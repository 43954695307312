import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function storeUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'store_id', label: 'ID', sortable: true },
    { key: 'company_name', label: '渠道名称', sortable: true },
    { key: 'store_name', label: '店铺名称', sortable: true },
    { key: 'store_no', label: '店铺编码', sortable: true },
    //{ key: 'company_id', label: '主体ID', sortable: true },
    { key: 'companyname', label: '公司主体', sortable: true },
    { key: 'returnmoney_companyname', label: '回款主体', sortable: true },
/*    { key: 'province_id', label: '省', sortable: true },
    { key: 'city_id', label: '城市', sortable: true },*/
    //{ key: 'user_pass', label: '登录密码', sortable: true },
    //{ key: 'store_brand', label: '店铺所属品牌    store_brand', sortable: true },
    { key: 'user_name', label: '登录账户', sortable: true },
    //{ key: 'image', label: '店铺图片', sortable: true },
    //{ key: 'memo', label: '其他信息', sortable: true },
    { key: 'create_time', label: '创建时间' },
    { key: 'creator', label: '创建人', },
    { key: 'update_time', label: 'Token更新时间', sortable: true },
    { key: 'expire_time_token', label: 'Token过期时间', sortable: true },
    /*{ key: 'updator', label: '更新人ID', sortable: true },*/
     //{ key: 'check_time', label: '审核时间' },
     { key: 'checker', label: '审核人' },
     { key: 'attachments', label: '相关附件' },
    { key: 'status', label: '状态'},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('store_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('store/searchAll', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        status:3,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
